$(function(){
  
	$('.expand').click(function() {
      $(this).next('div, ul').slideToggle( "fast", function() {
      });
      $(this).toggleClass('open');
    });

  $('.coach-dashboard h3#prepare').addClass('open');

  $('.prepare').click(function() {
      $('#prepare').next('div').slideDown( "fast", function() {
        $('#prepare').addClass('open');
      });
    });

    $('.research').click(function() {
      $('#research-verify').next('div').slideDown( "fast", function() {
        $('#research-verify').addClass('open');
      });
    });

    $('.enroll').click(function() {
      $('#enroll').next('div').slideDown( "fast", function() {
        $('#enroll').addClass('open');
      });
    });

    $('.completed').click(function() {
      $('#completed').next('div').slideDown( "fast", function() {
        $('#completed').addClass('open');
      });
    });

    $('.not-started').click(function() {
      $('#not-started').next('div').slideDown( "fast", function() {
        $('#not-started').addClass('open');
      });
    });

    // smooth scrolling for jump links
    $('a[href*=#]').click(function() {
      if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
        var target = $(this.hash);
        target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
        if (target.length) {
          $('html,body').animate({
            scrollTop: target.offset().top
          }, 1000);
          return false;
        }
      }
    });

    var updatedCoaches = function() {

      // Initial Values
      var siteId = $('#network_site option:selected').attr('value');
      var hasResults = false;

      // Temporarily enables coach select and removes helper text
      $('#coach').removeAttr('disabled');
      $('.select-coach').css('display', 'none');

      // Loops through coaches and disables ones that don't match network site id
      $('#coach option').each(function(){
        var coachSiteId = $(this).attr('data-site');
        if(coachSiteId == siteId || $(this).val() == '') {
          $(this).prop('disabled', false);
          hasResults = true
          setTimeout(function(){
            $('.no-results').css('display', 'none');
          }, 0);
        } else {
          $(this).prop('disabled', true);
        }
      });
      
      // Determines if there are no results and coaches select needs to be re-disabled.
      if(!hasResults) {
        $('#coach').val(null).trigger('change');
        $('#coach').prop('disabled', true);
        $('.no-results').css('display', 'inline');
      } 

    }

    // If network is already selected, enable coach select
    if($('#network_site').length) {
      if ($('#network_site').val()) {
        $('#coach').removeAttr('disabled');
        updatedCoaches();
      } 
    }

    // Add Student Form Validation
    $('#network_site').change(function(){
      updatedCoaches();
      $('#coach').val(null).trigger('change');
      // Select2 reset
      $("#coach").select2('destroy');
      
      if ($("#coach").data("allowclear")) {
        $("#coach").select2({
          allowClear: true
        }).on("select2:unselecting", function(e) {
          $(this).data('state', 'unselected');
        }).on("select2:open", function(e) {
          if ($(this).data('state') === 'unselected') {
            $(this).removeData('state'); 
        
            var self = $(this);
            setTimeout(function() {
              self.select2('close');
            }, 1);
          }    
        });
      } else {
        $("#coach").select2();
      }
    });

    var $milestone = $('select[name="milestone"]');
    var $task      = $('select[name="task"]');
    var $creator   = $('select[name="creator"]');
    var $resources = $('table.resources tbody tr');
    var filters    = [];

    /* Resource Filter */
    $('.list-filter').on('click', function(){

      var resourceCount = 0;

      filters = [
        {
          name: 'source',
          value: $creator.val()
        },
        {
          name: 'milestone',
          value: $milestone.val()
        },
        {
          name: 'task',
          value: $task.val()
        }
      ];
      $resources.each(function(){
        var $this = $(this);
        var tempArray = [];

        for(var i = 0; i < filters.length; i++) {
          if (filters[i].value == $this.attr('data-' + filters[i].name) || filters[i].value == "") {
            tempArray[i] = true;
          } else {
            tempArray[i] = false;
          }
        }

        if (tempArray.every(allIsTrue)) {
          $this.css('display', 'table-row');
          resourceCount++;
        } else {
          $this.css('display', 'none');
        }
      });

      if(resourceCount > 0) {
        $('.messaging.notice.dynamic').css('display', 'none');
      } else {
        $('.messaging.notice.dynamic').css('display', 'block');
      }

    });

    function allIsTrue(currentValue) {
      return currentValue == true;
    };

    /* Coach Task Reopen Reason Form */
    const $reasonSelect = $('.js-reasonSelect');
    const $otherReason = $('.js-otherReason');
    const $reasonTextArea = $otherReason.find('textarea');

    $otherReason.hide();

    $reasonSelect.change(function() {
      const value = $reasonSelect.val().toLowerCase();

      if (value === 'other') {
        $otherReason.fadeIn(250);
        $reasonTextArea.prop('required', true);
      } else {
        $otherReason.fadeOut(250);
        $reasonTextArea.prop('required', false);
      }
    });



});
